export default {
  light: {
    primary: "#223947",
    secondary: "#EE44AA",
    error: "#FF4081",
    warning: "#fb8c00",
    success: "#3CD4A0",
    info: "#9013FE",
    textColor: "#B9B9B9",
    iconColor: "rgb(255, 255, 255)",
    grayBold: "#4a4a4a",
  },
};
