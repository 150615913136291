import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "homepage",
  //   component: () =>
  //     import(/* webpackChunkName: "homepage" */ "@/views/Homepage/Homepage"),
  //   meta: {
  //     title: "VTU Silo",
  //   },
  // },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Auth/Login"),
    meta: {
      title: "Login | VTU Silo",
    },
  },

  {
    path: "/forgot_password",
    name: "forgotPass",
    component: () =>
      import(
        /* webpackChunkName: "forgotPass" */ "@/views/Auth/ForgotPassword"
      ),
    meta: {
      title: "Forgot Password | VTU Silo",
    },
  },

  {
    path: "/reset_password",
    name: "resetPass",
    component: () =>
      import(/* webpackChunkName: "resetPass" */ "@/views/Auth/ResetPassword"),
    meta: {
      title: "Reset Password | VTU Silo",
    },
  },

  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Auth/Register"),
    meta: {
      title: "Register | VTU Silo",
    },
  },

  {
    path: "/register/:refcode",
    name: "ref-register",
    component: () =>
      import(/* webpackChunkName: "register2" */ "@/views/Auth/Register"),
    meta: {
      title: "Register | VTU Silo",
    },
  },

  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/components/Layout"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Dashboard/Dashboard"),
        meta: {
          auth: "user",
          title: "Dashboard | VTU Silo",
        },
      },
      {
        path: "buy_data",
        name: "data",
        component: () =>
          import(/* webpackChunkName: "data" */ "@/views/Data/Data"),
        meta: {
          auth: "user",
          title: "Buy Data | VTU Silo",
        },
      },
      {
        path: "buy_airtime",
        name: "airtime",
        component: () =>
          import(/* webpackChunkName: "airtime" */ "@/views/Airtime/Airtime"),
        meta: {
          auth: "user",
          title: "Buy Airtime | VTU Silo",
        },
      },
      {
        path: "buy_cabletv",
        name: "cabletv",
        component: () =>
          import(/* webpackChunkName: "cabletv" */ "@/views/CableTv/CableTv"),
        meta: {
          auth: "user",
          title: "Buy Cable TV | VTU Silo",
        },
      },
      {
        path: "buy_electricity",
        name: "electricity",
        component: () =>
          import(
            /* webpackChunkName: "electricity" */ "@/views/Electricity/Electricity"
          ),
        meta: {
          auth: "user",
          title: "Buy Electricity | VTU Silo",
        },
      },
      {
        path: "transactions",
        name: "transactions",
        component: () =>
          import(
            /* webpackChunkName: "transactions" */ "@/views/Transactions/Transactions"
          ),
        meta: {
          auth: "user",
          title: "Transactions | VTU Silo",
        },
      },
      {
        path: "fund_wallet",
        name: "fund_wallet",
        component: () =>
          import(
            /* webpackChunkName: "fund_wallet" */ "@/views/FundWallet/FundWallet"
          ),
        meta: {
          auth: "user",
          title: "Fund Wallet | VTU Silo",
        },
      },
      {
        path: "account_details",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/Profile/Profile"),
        meta: {
          auth: "user",
          title: "Account Details | VTU Silo",
        },
      },
      {
        path: "bonus",
        name: "bonus",
        component: () =>
          import(/* webpackChunkName: "bonus" */ "@/views/Bonus/Bonus"),
        meta: {
          auth: "user",
          title: "Bonus | VTU Silo",
        },
      },
      {
        path: "success-page/:txref",
        name: "success-page",
        component: () =>
          import(
            /* webpackChunkName: "success-page" */ "@/components/SuccessPage"
          ),
        meta: {
          auth: "user",
          title: "Success | VTU Silo",
        },
        beforeEnter: (to, from, next) => {
          if (
            !store.getters.getTransDetails ||
            store.getters.getTransDetails.txref !== to.params.txref
          )
            next({ name: "notFound" });
          else next();
          return false;
        },
      },
      {
        path: "upgrade-success/:data",
        name: "upgrade-success",
        component: () =>
          import(
            /* webpackChunkName: "upgrade-success" */ "@/views/Profile/UpgradeSuccess"
          ),
        props: true,
        meta: {
          auth: "user",
          title: "Success | VTU Silo",
        },
      },
      {
        path: "genaccount-success",
        name: "genaccount-success",
        component: () =>
          import(
            /* webpackChunkName: "genaccount-success" */ "@/views/Dashboard/GenerateAccountSuccess"
          ),
        meta: {
          auth: "user",
          title: "Success | VTU Silo",
        },
      },
    ],
  },

  {
    path: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "error-404" */ "@/views/Error/Error"),
    meta: {
      title: "404 Page - VTU Silo",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
});

export default router;
